<template>
    <div class="filters_container" :class="{reveal: open}">
        <div class="filters">
            <h2>Filtros</h2>
            <div class="filter_list_tabs">
                <div class="filter_list_tab" @click="tab=index" :class="{tab_active: index==tab, filters_active: haveFilter(index)}" v-for="(name, index) in tabNames" :key="name">{{name}}</div>
            </div>
            <div class="filter_list">
                <div class="filter_list_header">
                    <h3>{{tabNames[tab]}}</h3>
                    <input type="text" placeholder="Búscar" v-model="find">
                </div>
                <div class="filter_tag_nav">
                    <div class="filter_tag_btn" @click="add(obj.id)" :class="{selected: obj.selected}" v-for="(obj, index) in objs" :key="index+Math.random()">{{obj.el}}</div>
                </div>
            </div>
        </div>
        <div class="reset_filter_btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"/></svg>Borrar filtros
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Filtros',
    props:{
        open: {
            type: Boolean
        },
        random:{
            type: Number,
            default: function(){
                return Math.random();
            }
        }
    },
    data: function(){
        return{
            tab: 0,
            tabNames: [
                'Actores',//0
                'Directores',//1
                'Categorias',//2
                'Años',//3
                'Nacionalidades',//4
                'Productoras',//5
                'Familias',//6
            ],

            objs: [],
            find: null
        }
    },
    methods:{
        add: function(id){
            switch(this.tab){
                case 0:
                    if(this.$parent.actores.includes(id))
                        this.$parent.actores.splice(this.$parent.actores.indexOf(id), 1);
                    else
                        this.$parent.actores.push(id);
                    this.$parent.getPelis(1);
                    this.init();
                    break;
                case 1:
                    if(this.$parent.directores.includes(id))
                        this.$parent.directores.splice(this.$parent.directores.indexOf(id), 1);
                    else
                        this.$parent.directores.push(id);
                    this.$parent.getPelis(1);
                    this.init();
                    break;
                case 2:
                    if(this.$parent.categorias.includes(id))
                        this.$parent.categorias.splice(this.$parent.categorias.indexOf(id), 1);
                    else
                        this.$parent.categorias.push(id);
                    this.$parent.getPelis(1);
                    this.init();
                    break;
                case 3:
                    if(this.$parent.anos.includes(id))
                        this.$parent.anos.splice(this.$parent.anos.indexOf(id), 1);
                    else
                        this.$parent.anos.push(id);
                    this.$parent.getPelis(1);
                    this.init();
                    break;
                case 4:
                    if(this.$parent.paises.includes(id))
                        this.$parent.paises.splice(this.$parent.paises.indexOf(id), 1);
                    else
                        this.$parent.paises.push(id);
                    this.$parent.getPelis(1);
                    this.init();
                    break;
                case 5:
                    if(this.$parent.productoras.includes(id))
                        this.$parent.productoras.splice(this.$parent.productoras.indexOf(id), 1);
                    else
                        this.$parent.productoras.push(id);
                    this.$parent.getPelis(1);
                    this.init();
                    break;
                case 6:
                    if(this.$parent.familias.includes(id))
                        this.$parent.familias.splice(this.$parent.familias.indexOf(id), 1);
                    else
                        this.$parent.familias.push(id);
                    this.$parent.getPelis(1);
                    this.init();
                    break;
            }
        },
        haveFilter: function(type){
            switch(type){
                case 0:
                    if(this.$parent.actores.length>0)
                        return true;
                    return false;
                case 1:
                    if(this.$parent.directores.length>0)
                        return true;
                    return false;
                case 2:
                    if(this.$parent.categorias.length>0)
                        return true;
                    return false;
                case 3:
                    if(this.$parent.anos.length>0)
                        return true;
                    return false;
                case 4:
                    if(this.$parent.paises.length>0)
                        return true;
                    return false;
                case 5:
                    if(this.$parent.productores.length>0)
                        return true;
                    return false;
                case 6:
                    if(this.$parent.familias.length>0)
                        return true;
                    return false;
            }
        },
        getActores: function(){
            axios
                .get(process.env.VUE_APP_URL+`actors`, {
                    params: {
                        'orderBy[column]': 'created_at',
                        'orderBy[order]': 'desc',
                        search: this.find,
                        limit: 25,
                        user: this.$store.state.user
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        let temp = [];
                        rs.data.forEach(element => {
                            temp.push({
                                id: element.id,
                                el: element.name,
                                selected: this.$parent.actores.includes(element.id)
                            });
                        });
                        this.objs = temp;
                    }
                })
               .catch(error => this.$response_error(error))
        },
        getDirectores: function(){
            axios
                .get(process.env.VUE_APP_URL+`directors`, {
                    params: {
                        'orderBy[column]': 'created_at',
                        'orderBy[order]': 'desc',
                        search: this.find,
                        limit: 25,
                        user: this.$store.state.user,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        let temp = [];
                        rs.data.forEach(element => {
                            temp.push({
                                id: element.id,
                                el: element.name,
                                selected: this.$parent.directores.includes(element.id)
                            });
                        });
                        this.objs = temp;
                    }
                })
                .catch()
        },
        getCategorias: function(){
            axios
                .get(process.env.VUE_APP_URL+`categories`, {
                    params: {
                        'orderBy[column]': 'created_at',
                        'orderBy[order]': 'desc',
                        search: this.find,
                        limit: 25,
                        user: this.$store.state.user,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        let temp = [];
                        rs.data.forEach(element => {
                            temp.push({
                                id: element.id,
                                el: element.name,
                                selected: this.$parent.categorias.includes(element.id)
                            });
                        });
                        this.objs = temp;
                    }
                })
                .catch()
        },
        getYears: function(){
            axios
                .get(process.env.VUE_APP_URL+`years`, {
                    params: {
                        order: 'desc',
                        search: this.find,
                        limit: 25,
                        user: this.$store.state.user,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        let temp = [];
                        rs.data.forEach(element => {
                            temp.push({
                                id: element.year,
                                el: element.year,
                                selected: this.$parent.anos.includes(element.year)
                            });
                        });
                        this.objs = temp;
                    }
                })
                .catch()
        },
        getPaises: function(){
            axios
                .get(process.env.VUE_APP_URL+`countries`, {
                    params: {
                        order: 'desc',
                        search: this.find,
                        limit: 25,
                        user: this.$store.state.user,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        let temp = [];
                        rs.data.forEach(element => {
                            temp.push({
                                id: element.country,
                                el: element.country,
                                selected: this.$parent.paises.includes(element.country)
                            });
                        });
                        this.objs = temp;
                    }
                })
                .catch()
        },
        getProductoras: function(){
            axios
                .get(process.env.VUE_APP_URL+`producers`, {
                    params: {
                        'orderBy[column]': 'created_at',
                        'orderBy[order]': 'desc',
                        search: this.find,
                        limit: 25,
                        user: this.$store.state.user,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        let temp = [];
                        rs.data.forEach(element => {
                            temp.push({
                                id: element.id,
                                el: element.name,
                                selected: this.$parent.productores.includes(element.id)
                            });
                        });
                        this.objs = temp;
                    }
                })
                .catch()
        },
        getFamilias: function(){
            axios
                .get(process.env.VUE_APP_URL+`families`, {
                    params: {
                        'orderBy[column]': 'created_at',
                        'orderBy[order]': 'desc',
                        search: this.find,
                        limit: 25,
                        user: this.$store.state.user,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        let temp = [];
                        rs.data.forEach(element => {
                            temp.push({
                                id: element.id,
                                el: element.name,
                                selected: this.$parent.familias.includes(element.id)
                            });
                        });
                        this.objs = temp;
                    }
                })
                .catch(error => this.$response_error(error))
        },
        init: function(){
            switch(this.tab){
                case 0:
                    this.getActores();
                    break;
                case 1:
                    this.getDirectores();
                    break;
                case 2:
                    this.getCategorias();
                    break;
                case 3:
                    this.getYears();
                    break;
                case 4:
                    this.getPaises();
                    break;
                case 5:
                    this.getProductoras();
                    break;
                case 6:
                    this.getFamilias();
                    break;
            }
        }
    },
    watch: {
        tab: function(){
            this.init();
        },
        find: function(){
            this.init();
        },
        random: function(){
            this.init();
        }
    },
    created: function(){
        this.init();
    }
}
</script>