import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cookies: false,
    user: null,
    landing: false,
    family: null
  },
  mutations: {
    cookies: (state, connections) => {
      localStorage.setItem('mensajeinit', true);
      state.cookies = true;
    },
    login: (state, connections) => {
      localStorage.setItem('user', connections);
      state.user = connections;
    },
    logout: (state, connections) => {
      localStorage.removeItem('user');
      state.user = null;
    },
    landing: (state, connections) => {
      localStorage.setItem('landing', connections);
      state.landing = connections;
    },
    family: (state, connections) => {
      localStorage.setItem('family', connections);
      state.family = connections;
    },
    initialStore(state){
      if(localStorage.getItem('mensajeinit'))
          state.cookies = localStorage.getItem('mensajeinit') == 'true';
      if(localStorage.getItem('user'))
          state.user = localStorage.getItem('user');
      if(localStorage.getItem('landing'))
          state.landing = localStorage.getItem('landing') == 'true';
      if(localStorage.getItem('family'))
          state.family = localStorage.getItem('family');
    },
  },
  actions: {
  },
  modules: {
  }
})
