<template>
    <main class="dinamic_content">
        <transition :name="tipoTransferencia" >
            <Index v-if="paso==0"/>
            <Stp v-else-if="paso==1"/>
            <Login v-else-if="paso==-1"/>
            <ResetPass v-else-if="paso==-2"/>
            <ResetPass2 v-else-if="paso==-3"/>
        </transition>
    </main>
</template>

<script>
import Index from '@/components/Externa/Index.vue';
import Stp from '@/components/Externa/Stp.vue';
import Login from '@/components/Externa/Login.vue';
import ResetPass from '@/components/Externa/ResetPass.vue';
import ResetPass2 from '@/components/Externa/ResetPass2.vue';

export default {
    name: 'Externa',
    components: {
        Stp,
        Index,
        Login,
        ResetPass,
        ResetPass2
    },
    data: function(){
        return{
            paso: null,
            tipoTransferencia: 'externa_right'
        }
    },
    methods:{
        init: function(){
            let old = this.paso;
            if(this.$route.name == 'Registro')
                this.paso = 1
            else if(this.$route.name == 'Login')
                this.paso = -1
            else if(this.$route.name == 'Recuperar')
                this.paso = -2
            else if(this.$route.name == 'Recuperar2')
                this.paso = -3
            else
                this.paso = 0;
                
            if(this.paso > old || (this.paso<0 && old < this.paso))
                this.tipoTransferencia = 'externa_right';
            else
                this.tipoTransferencia = 'externa_left';
        }
    },
    created: function(){
        this.init();
    },
    watch:{
        $route: function(){
            this.init();
        }
    }
}
</script>

<style>
    .externa_right-enter, .externa_left-leave-to{
        -webkit-transform: translate3d(100vw,0,0) !important;
        transform: translate3d(100vw,0,0) !important;
    }
    .externa_right-enter-to, .externa_right-leave, .externa_left-enter-to, .externa_left-leave{
        -webkit-transform: none;
        transform: none;
    }
    .externa_right-leave-to, .externa_left-enter{
        -webkit-transform: translate3d(-100vw,0,0) !important;
        transform: translate3d(-100vw,0,0) !important;
    }
</style>