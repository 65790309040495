<template>
    <div class="dinamic_container full_container state_center">
        <div class="floating_content">
            <div class="inner">
                <div class="box">
                    <div class="box_block w50 offsetw25 login_block floating_element floating_element_a">
                        <div class="h2">Recupera tu contraseña</div>
                        <p>Para poder recuperar tu contraseña introduce el mail con el que te registraste.</p>
                        <div class="form">
                            <div class="input_block">
                                <input type="email" v-model="email" ref="email" placeholder="Email" tabindex="0" class="fixed">
                            </div>
                            <div class="input_block">
                                <div @click="recuperarPassword" class="btn wide big login_btn down"><span>Enviar</span></div>
                            </div>
                            <div class="input_block" @click="$router.push('/login')">
                                <div class="simple_link dark icon_link" id="volverALoginLbl">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.222 18.917c5.666-5.905-.629-10.828-5.011-7.706l1.789 1.789h-6v-6l1.832 1.832c7.846-6.07 16.212 4.479 7.39 10.085z"/></svg>Volver atras</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Recuperar',
    data: function(){
        return{
            email: null
        }
    },
    methods:{
        recuperarPassword: function(){
            if(!this.email){
                this.$parent.$parent.error = {type: 2, msg: 'Introduce el email antes de enviar'};
                return;
            }
            axios
                .post(process.env.VUE_APP_URL+`recover_password`, {
                    email: this.email,
                })
                .then(response => {
                    let rs = response.data;
                    this.$parent.$parent.error = {type: 1, msg: 'Mensaje enviado con éxito'};
                })
                .catch(error => this.$response_error(error))
        }
    },
    mounted: function(){
        setTimeout(() => {this.$refs.email.focus();}, 1000);
    }
}
</script>