import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: function () {
      return import('@/App.vue')
    }
  },

  /** INTERNAS */
  {
    path: '/cartelera',
    props: true,
    name: 'Cartelera',
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/film/:id',
    name: 'Pelicula',
    props: true,
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/cuenta',
    name: 'Cuenta',
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/novedades',
    name: 'Novedades',
    component: function () {
      return import('@/App.vue')
    }
  },

  /** EXTERNAS */
  {
    path: '/registro',
    name: 'Registro',
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/recuperar',
    name: 'Recuperar',
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/recover_password/:token',
    name: 'Recuperar2',
    component: function () {
      return import('@/App.vue')
    }
  },

  /** COMUNES */
  {
    path: '/legal',
    name: 'Legal',
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/about',
    name: 'About',
    component: function () {
      return import('@/App.vue')
    }
  },
  {
    path: '/premium',
    name: 'Premium',
    component: function () {
      return import('@/App.vue')
    }
  },

  {
    path: "*",
    name: 'Not Found',
    component: () => import("@/views/404.vue")
  }
]

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
