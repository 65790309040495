<template>
    <div>
        <h2>DATOS PERSONALES</h2>

        <div class="form">
            <form method="post" id="fUser">											
                <div class="input_block">
                    <label>Actual contraseña</label>
                    <input class="data_inp" type="password" v-model="old_password">
                </div>
                <div class="input_block">
                    <label>Cuenta de correo</label>
                    <input class="data_inp" type="mail" v-model="email">
                </div>
                <div class="input_block">
                    <label>Nueva contraseña</label>
                    <input class="data_inp" type="password" v-model="password">
                </div>
                <div class="input_block">
                    <label>Repetir nueva contraseña</label>
                    <input class="data_inp" type="password" v-model="confirm_password">
                </div>
                <div class="input_block" @click="save()">
                    <div class="btn" style="margin-top: 20px;">
                        <span>Guardar cambios</span>
                    </div>
                </div>
                <div class="delete_account" @click="borrar()">Eliminar mi cuenta</div>
                <div class="clear"></div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'usuario',
    data: function(){
        return{
            old_password: null,
            email: null,
            password: null,
            confirm_password: null
        }
    },
    methods:{
        save: function(){
            if(!this.password && !this.email){
                this.$parent.$parent.error = {
                    type: 2,
                    msg: 'Rellena almenos algun campo'
                };
                return;
            }
            
            if(!this.old_password){
                this.$parent.$parent.error = {
                    type: 2,
                    msg: 'Falta La contraseña actual'
                };
                return;
            }

            if(this.password){
                if(this.password.length < 8){
                    this.$parent.$parent.error = {
                        type: 2,
                        msg: 'La contraseña no puede tener menos de 8 caracteres'
                    };
                    return;
                }
                
                if(this.password != this.confirm_password){
                    this.$parent.$parent.error = {
                        type: 2,
                        msg: 'Las contraseñas no coinciden'
                    };
                    return;
                }
            }

            axios
                .post(process.env.VUE_APP_URL+`users/update`, {
                    user: this.$store.state.user,
                    old_password: this.old_password,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.confirm_password
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.old_password = null;
                        this.email = null;
                        this.password = null;
                        this.confirm_password = null;
                        this.$parent.$parent.error = {
                            type: 1,
                            msg: 'Cambiado correctamente'
                        };
                    }
                    else
                        this.$parent.$parent.error = {
                            type: 2,
                            msg: rs.msg
                        };
                })
                .catch(error => {
                    this.$parent.$parent.error = {
                        type: 2,
                        msg: 'Fallo al guardar'
                    };
                })
        },
        borrar: function(){
            axios
                .delete(process.env.VUE_APP_URL+`users`, {
                    params:{
                        user: this.$store.state.user
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.$store.commit('logout');
                        window.location.replace("/");
                    }
                    else
                        this.$parent.$parent.error = {
                            type: 2,
                            msg: rs.msg
                        };
                })
                .catch(error => {
                    this.$parent.$parent.error = {
                        type: 2,
                        msg: 'Fallo al eliminar'
                    };
                })
        }
    }
}
</script>