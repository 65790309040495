<template>
    <section class="contact_page center_inner">
        <div class="floating_content">
            <div class="inner">
                <div class="box">
                    <div class="box_block w50 offsetw25 login_block">
                        <hgroup class="section_heading centered">
                            <h2>Contacto</h2>
                            <p>¿Tienes alguna duda? ¿quieres hacernos algún comentario?, puedes hacerlo a través del siguiente formulario.</p>
                        </hgroup>
                        <div class="form">
                            <div class="input_block">
                                <input type="text" v-model="contacto.name" placeholder="Nombre" tabindex="0" class="fixed">
                            </div>
                            <div class="input_block">
                                <input type="email"  v-model="contacto.email" placeholder="Email" tabindex="0" class="fixed">
                            </div>
                            <div class="input_block">
                                <select v-model="contacto.type">
                                    <option value="" selected disabled>Motivo de tu consulta</option>
                                    <option disabled>--</option>
                                    <option value="Facturacion">Facturación</option>
                                    <option value="Contenidos">Contenidos</option>
                                    <option value="Soporte">Soporte</option>
                                </select>
                            </div>
                            <div class="input_block">
                                <textarea  v-model="contacto.message" placeholder="¿Qué quieres comentarnos?"></textarea>
                            </div>
                            <div class="input_block">
                                <div @click="sendContacto" class="btn wide dark big contacto_btn down"><span>Enviar</span></div>
                                <p class="send_legal">Haciendo clic en enviar aceptas nuestros <a href="/legal" target="_blank">Términos y condiciones</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Contacto',
    data: function(){
        return{
            contacto: {name: null, email: null, type:null, message: null}
        }
    },
    methods:{
        sendContacto: function(){
            console.log(this.contacto);
            if(!this.contacto.name || !this.contacto.email || !this.contacto.type || !this.contacto.message){
                this.$parent.error = {type: 2, msg: 'Rellena todos los datos para enviar'};
                return;
            }
            axios
                .post(process.env.VUE_APP_URL+`contact`, {
                        name: this.contacto.name,
                        email: this.contacto.email,
                        type: this.contacto.type,
                        message: this.contacto.message,
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.$parent.error = {type: 1, msg: 'Mensaje enviado con éxito'};
                    else    
                        this.$parent.error = {type: 2, msg: rs.msg};
                })
                .catch(error => this.$response_error(error))
        },
    }
}
</script>