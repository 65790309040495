<template>
    <div>
        <h2>FAVORITOS</h2>
        <div class="table_wrapper">
            <table class="table history" border="0" cellpadding="0" cellspacing="0">
                <tr>
                    <th>Película</th>
                    <th>Ver</th>
                    <th>Quitar</th>
                </tr>
                <tr :class="{odds: index%2!=0}" v-for="(favorito, index) in favorites" :key="index">
                    <td>
                        <router-link :to="`/film/${favorito.film.id}`" class="verPeli">{{favorito.film.title}}</router-link>
                    </td>
                    <td class="watch_movie">
                        <router-link :to="`/film/${favorito.film.id}`" class="verPeli"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"/></svg></router-link>
                    </td>
                    <td class="watch_movie">
                        <div @click="eliminar(favorito.film.id)" class="verPeli">
                            <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"/></svg>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Historial',
    props:{
        favorites:{
            type: Array
        }
    },
    methods: {
        eliminar: function(id){
            axios
                .post(process.env.VUE_APP_URL+`favorites`, {
                    user: this.$store.state.user,
                    film: id
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.$parent.getFavoritos();
                        this.$parent.$parent.error = {
                            type: 1,
                            msg: 'Eliminado correctamente'
                        };
                    }
                    else
                        this.$parent.$parent.error = {
                            type: 2,
                            msg: rs.msg
                        };
                })
                .catch(error => this.$response_error(error))
        }
    }
}
</script>