<template>
    <div class="slider"
        @mouseover="hover = true" 
        @mouseleave="hover = false">
        <div class="slider_list_slides">
            <div class="slider_trail"  
                :style="{'transform': `translate3d(${actual * -100}%, 0px, 0px)`,
                        '-webkit-transition': `all ${parallaxTime}s cubic-bezier(.86,0,.07,1)`,
                        '-moz-transition': `all ${parallaxTime}s cubic-bezier(.86,0,.07,1)`,
                        '-o-transition': `all ${parallaxTime}s cubic-bezier(.86,0,.07,1)`,
                        '-ms-transition': `all ${parallaxTime}s cubic-bezier(.86,0,.07,1)`,
                        'transition': `all ${parallaxTime}s cubic-bezier(.86,0,.07,1)`}"
                draggable
                @dragstart="dragStart($event)" 
                @dragend="dragStop($event)"
                @drag="dragOver($event)">
                <div class="slider_slides">
                    <div class="slider_item with_caption"
                        v-for="(obj, index) in data" 
                        :key="index"
                        @click="$emit('click', obj.returnData)"
                        :style="{'padding-bottom': imgRatio}"
                    >
                        <div class="slider_caption">
                            <div v-show="obj.title" class="slider_caption_main">{{obj.title}}</div>
                            <div v-show="obj.subTitle" class="slider_caption_sub" v-html="obj.subTitle"></div>
                        </div>
                        <img :src="obj.img" v-show="obj.img" class="slider_img" :style="{transform: `translateX(${calcParallax(index)}%)`}"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider_list_nav">
            <div v-if="arrows" class="slider_btn left" @click="actual -= 1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg></div>
            <div v-if="dots" class="slider_nav">
                <div class="slider_nav_item" 
                    :class="actual == index ? 'current_btn' : ''" 
                    v-for="(obj, index) in dat" :key="index" 
                    @click="goTo(index)">
                </div>
            </div>
            <div v-if="arrows" class="slider_btn right" @click="actual += 1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VitySlide',
    props:{
        dat: {
            type: Array,
            default: function(){
                return [];
            }
        },
        autoplay:{
            type: Boolean,
            default: false
        },
        autoplayTime:{
            type: Number,
            default: 3//EN SEGUNDOS Y CON DECIMALES SI ES NECESARIO
        },
        autoplaySentido:{
            type: Number,
            default: 1//SI ES 1 VA A LA DERECHA. SI ES 0 VA A IZQUIERDA 
        },
        onHoverPause:{
            type: Boolean,
            default: true
        },
        parallax:{
            type: Boolean,
            default: true
        },
        dots:{
            type: Boolean,
            default: true
        },
        arrows:{
            type: Boolean,
            default: true
        },
        imgRatio:{
            type: String,
            default: '50%'
        }
    },
    data: function(){
        return{
            actual: 0,
            drag: null,
            dragActual: null,
            hover: false,
            oldParallax: null,
            isParallax: this.parallax,
            parallaxTime: 1,
            intervalAutoplay: null
        }
    },
    created: function(){
        this.init();
    },
    watch:{
        dat: function(){
            this.init();
        },
        actual: function(newval, oldval){
            if(newval < 0)
                this.actual = this.data.length-1;
            else if(newval >= this.data.length){
                this.actual = 0;
                /*this.data.push(this.data[0]);
                var temp = this.parallaxTime;
                setTimeout(() => {
                    this.parallaxTime = 0;
                    this.actual = 0;
                    setTimeout(() => {
                        this.data.pop();
                        this.parallaxTime = 1;
                    }, temp * 1000);
                }, temp * 1000);*/
                this.resetAutoplay();
            }
        },
        parallax: function(){
            this.isParallax = this.parallax;
        },
    },
    methods: {
        init: function(){
            this.data = this.dat;
            this.resetAutoplay();
        },
        resetAutoplay: function(){
            clearInterval(this.intervalAutoplay);
            if(this.autoplay){
                this.intervalAutoplay = setInterval(() => {
                    if(!this.hover || !this.onHoverPause){
                        if(this.autoplaySentido == 1)
                            this.actual += 1;
                        else if(this.autoplaySentido == 0)
                            this.actual -= 1;
                    }
                }, this.autoplayTime * 1000);
            }
        },
        goTo: function(id){
            this.oldParallax = this.isParallax;
            this.isParallax = false;
            this.actual = id;
            setTimeout(() => {
                this.isParallax = this.oldParallax;
                this.oldParallax = null;
            }, this.parallaxTime * 1000);
            this.resetAutoplay();
        },
        dragStart: function(evt){
            //evt.preventDefault();
            this.drag = evt.clientX;
            this.dragActual = this.actual;
            console.log('entra');
        },
        dragOver: function(evt){
            if(this.drag > evt.clientX)//IMAGEN HACIA LA DERECHA Y RATON A LA IZQUIERDA
                this.actual = Math.floor(this.actual) + ((this.drag - evt.clientX)/1000);
            else if(this.drag <= evt.clientX)//IMAGEN HACIA LA IZQUIERDA Y RATON A LA DERECHA
                this.actual = Math.floor(this.dragActual) - ((evt.clientX - this.drag)/700);
        },
        dragStop: function(evt){
            if(this.drag > evt.clientX)//IMAGEN HACIA LA DERECHA Y RATON A LA IZQUIERDA
                this.actual = Math.round(this.actual);
            else if(this.drag <= evt.clientX)//IMAGEN HACIA LA IZQUIERDA Y RATON A LA DERECHA
                this.actual = Math.round(Math.floor(this.actual) - ((evt.clientX - this.drag)/700));
            //this.actual = Math.round(this.actual);
            this.drag = null;
            this.dragActual = null;
        },
        calcParallax: function(index){
            if(!this.parallax)
                return 0;
            if(this.actual == index)
                return 0;
            else if(this.actual+1 == index)
                return -50;
            else if(this.actual-1 == index)
                return 50;
        }
    }
}
</script>

<style>
    /************************************************************************************************ 
    slider
    ************************************************************************************************/
    .slider {position: relative;}
    .slider_list_slides {background-color: #fff;display: block;overflow: hidden;position: relative;z-index: 1;}
    .slider_trail {
        display: flex;
        flex-wrap: nowrap;
    }
    .slider_slides {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: row;
        flex-grow: 1;
        flex-shrink: unset;
        flex-wrap: nowrap;
        justify-content: flex-start;
        perspective: 1px;
    }
    .slider_item {display: block;overflow: hidden;width: 100%;position: relative;padding-bottom: 56%;
        flex-grow: 1;
        flex-shrink: 0;
    }
    .slider_img {display: block;z-index: 1;position: absolute;left: 0;top: 0;right: 0;bottom: 0;z-index: 1;
        -webkit-transition: all 1s cubic-bezier(.86,0,.07,1);  
        -moz-transition: all 1s cubic-bezier(.86,0,.07,1); 
        -o-transition: all 1s cubic-bezier(.86,0,.07,1);
        -ms-transition: all 1s cubic-bezier(.86,0,.07,1); 
        transition: all 1s cubic-bezier(.86,0,.07,1);
    }
    .slider_item.with_caption::before {content: '';position: absolute;top: 40%;right: 0;bottom: 0;left: 0;z-index: 2;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    }
    .slider_item .slider_caption {position: absolute;z-index: 3;right: 40px;bottom: 40px;left: 40px;}
    .slider_item .slider_caption label {display: block;margin-bottom: 10px;}
    .slider_item .slider_caption label span {background-color: #fff;display: inline-block;font-size: 12px;font-weight: bold;padding: 1px 6px;
        border-radius: 15px;
    }
    .slider_item .slider_caption .slider_caption_main {color: #fff;font-size: 42px;font-weight: 300;line-height: 50px;margin-bottom: 10px;}
    .slider_item .slider_caption .slider_caption_sub {color: #fff;font-size: 24px;font-weight: 300;}
    /*.slider_item .slider_caption .slider_caption_main, .slider_item .slider_caption .slider_caption_sub {white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}*/
    .slider_list_nav {margin-top: 20px;position: relative; 
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .slider_nav .slider_nav_item {display: inline-block;margin-right: 5px;}
    .slider_nav .slider_nav_btn {background-color: #ddd;cursor: pointer;width: 40px;height: 6px;
        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }
    .slider_nav .slider_nav_btn:hover {background-color: #999;}
    .slider_nav_item.current_btn .slider_nav_btn {background-color: #e60f45;}
    .slider_nav_item.current_btn .slider_nav_btn {pointer-events: none;}
    .slider_list_nav .slider_btn {cursor: pointer;width: 24px;height: 24px;position: absolute;top: 0;
        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }
    .slider_list_nav .slider_btn.left {left: 0;}
    .slider_list_nav .slider_btn.right {right: 0;}
    .slider_list_nav .slider_btn svg {fill: #ddd;width: 24px;height: 24px;
        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }
    .slider_list_nav .slider_btn:hover svg {fill: #000;}



    .slider .slider_nav_item.current_btn .slider_nav_btn {background-color: #333;}



</style>