<template>
    <div class="video_player_wrapper">
        <video
            ref="videoPlayer"
            class="video-js vjs-big-play-centered"
            controls
            paysinline
        >
            <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
            </p>
        </video>
    </div>
</template>

<script>
import 'video.js/dist/video-js.css';

export default {
    name: "VideoPlayer",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        histInterval: {type: Number, default(){return null;}}
    },
    data() {
        return {
            player: null
        }
    },
    mounted() {
        var time = this.options.currentTime;
        this.player = this.$video(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            this.requestFullscreen().catch(e => {});
            this.currentTime(time);
        });
        this.player.error(null);
    },
    beforeDestroy() {
        clearInterval(this.histInterval);
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>