<template>
    <section class="legal_page height_inner">
        <div class="inner">
            <div class="box_block">
                <hgroup class="section_heading centered">
                    <h3>Política de privacidad y precios</h3>
                </hgroup>
                <div class="text_block gold">

                    <h3>Política de privacidad</h3>

                    <p>Esta web es propiedad de la Asociación de Productores y Editores de Obras y Grabaciones para adultos (APEOGA), domiciliada en calle Hurtada, 31 Local, 28240 Hoyo de Manzanares, Madrid, con CIF: G84841899.</p>

                    <p>En el apartado de la solicitud de los datos del DNI, dichos datos no se almacenan en ningún fichero.</p>

                    <p>El documento de pago la modalidad contratada se destruirá una vez se compruebe que aparece en el banco el importe ingresado.</p>

                    <p>Dirección de email asociada al comentario. La IP y la dirección email nunca se publican sin pedir permiso al usuario.</p>
                    
                    <p>Dirección de email registrada en la sección de registro.</p>

                    <h3>Política de precios</h3>

                    <p>En cuanto a la política de precios siempre están reflejados los precios incluido el IVA correspondiente (21%).</p>
                    
                    <p>No se realizan pagos recurrentes, el cliente siempre controla sus pagos. 5 días antes de caducar el período contratado se le avisa al cliente mediante correo al email facilitado.
                    En caso de querer recibir factura la puede solicitar en el correo <a href="mailto:info@vivex.tv"><strong>info@vivex.tv</strong></a></p>

                    <p>Si deseas ejercer tu derecho a modificación o eliminación de alguno de estos datos, puedes hacerlo enviando un email a <a href="mailto:info@vivex.tv"><strong>info@vivex.tv</strong></a>, indicando la página en donde ese dato aparece publicado. Procederemos a la eliminación de nuestros servidores en un plazo máximo de 48 horas.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Legal'
}
</script>