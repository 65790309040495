<template>
    <transition name="loading" mode="out-in">
        <div class="loader transparent" style="opacity: 1" v-if="loading">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <main class="static_content" v-else>
            <section class="slider_container main_slider">
                <div class="slider_nav">
                    <div class="slider_nav_item"
                    v-for="(pelicula, index) in peliculas"
                    :key="index"
                    @click="posicion=index"
                    :class="{active: posicion==index}"
                    ></div>
                </div>
                <div class="slider main_slider" :style="{'transform': `translateY(-${posicion*100}vh)`}">
                    <div class="slide_item centered_content full_height" 
                    v-for="(pelicula, index) in peliculas"
                    :key="index"
                    :style="{'background-image': `url('${imgdb}posters/poster_${pelicula.url}.jpg')`}">
                        <h2>{{pelicula.title}}</h2>
                        <div class="btn big register_btn next down" @click="$router.push(`/film/${pelicula.id}`)">
                            <span>ver ahora</span>
                        </div>
                        <div class="bg_overlap"></div>
                        <video loop muted="muted" :poster="`${imgdb}posters/poster_${pelicula.url}.jpg`" playsinline autoplay>
                            <source :src="`${imgdb}shorts/short_${pelicula.url}.mp4`" type="video/mp4">
                        </video>
                    </div>
                </div>
            </section>
        </main>
    </transition>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Novedades',
    data: function(){
        return{
            loading: true,
            peliculas: [],
            posicion: 0,
            imgdb: null
        }
    },
    methods: {
        load: function(){
            this.getFamilias();
        },
        getFamilias: function(){
            axios
                .get(process.env.VUE_APP_URL+`families`, {
                    params: {
                        user: this.$store.state.user,
                        films: 1,
                        films_limit: 3,
                        'films_orderBy[order]': 'desc',
                        'films_orderBy[column]': 'actived_at',
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        rs.data.forEach(element => {
                            element.films.forEach(pelicula => {
                                this.peliculas.push(pelicula);
                            });
                        });
                        this.peliculas = shuffle(this.peliculas);
                    }
                    else
                        this.$parent.error = {type: 2, msg: rs.msg};
                })
                .catch(error => this.$response_error(error))
                .finally(() => setTimeout(() => this.loading=false, 500))
        },
        moverScroll: function(event){
            if (event.deltaY < 0){
                this.posicion -= 1;
            }
            else if (event.deltaY > 0){
                this.posicion += 1;
            }
        }
    },
    created: function(){
        this.imgdb = process.env.VUE_APP_URL_IMGDB;
        this.load();
        window.addEventListener('wheel', this.moverScroll);
    },
    watch: {
        posicion: function(newval, oldval){
            if(newval >= this.peliculas.length)
                this.posicion = 0;
            else if(newval < 0)
                this.posicion = this.peliculas.length-1;
        }
    },
    destroyed () {
        window.removeEventListener('wheel', this.moverScroll);
    },
}
</script>

<style>
    .loading-enter-active, .loading-leave-active {
        transition: opacity .5s;
    }
    .loading-enter, .loading-leave-to{
        opacity: 0;
    }
</style>